<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-4 col-lg-2 col-xl-3 col-xxl-2 rectangle">
                <div v-show="showHearts" class="overlay-heart"></div>
                <div v-show="showSuperHeart" class="overlay-superheart"></div>
                <div v-show="showX" class="overlay-x"></div>
                <div class="token-logo">
                    <img :src="imgLink" :alt="name">
                </div>
                <div class="info-container" id="viewer">
                    <p class="tokenInfo" v-show="!onlyMemesMode"><b>Name: </b>{{ name }} ({{ symbol }})</p>
                    <p class="tokenInfo" v-show="!onlyMemesMode"><b>Age: </b>{{ age }}</p>
                </div>
                <div class="action-buttons">
                    <button class="button x-button" @click="handleClick('x')"></button>
                    <button class="button super-heart-button" @click="handleClick('superHeart')"></button>
                    <button class="button heart-button" @click="handleClick('like')"></button>
                </div>
                <div v-if="showHearts" class="heart-cascade" :class="{ 'active': showHearts }">
                    <span v-for="heart in hearts" :key="heart">❤️</span>
                </div>
                <div v-if="showSuperHeart" class="super-heart-cascade" :class="{ 'active': showSuperHeart }">
                    <span v-for="superheart in hearts" :key="superheart">💕</span>
                </div>
                <div v-if="showX" class="x-cascade" :class="{ 'active': showX }">
                    <span v-for="x in hearts" :key="x">✖️</span>
                </div>
                <div>
                    <audio ref="heartAudio" src="https://d2y4xlojzk24f7.cloudfront.net/media/heartAudio.efb805db.wav"></audio>
                    <audio ref="xAudio" src="https://d2y4xlojzk24f7.cloudfront.net/media/xAudio.e7936183.wav"></audio>
                    <audio ref="superheartAudio" src="https://d2y4xlojzk24f7.cloudfront.net/media/superheartAudio.0bfad381.wav"></audio>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { generateClient } from 'aws-amplify/data'
    import * as subscriptions from '../graphql/subscriptions'
    import * as queries from '../graphql/queries'


    export default {
        name: 'Rectangle',
        props: {
            onlyMemesMode: Boolean,
            soundEnabled: Boolean
        },
        data() {
            return {
                client: null,
                subscription: null,
                name: 'Loading...',
                symbol: 'Loading...',
                address: 'Loading...',
                tokenCreateTime: null,
                age: 'Loading...',
                currentTime: null,
                imgLink: "https://d2y4xlojzk24f7.cloudfront.net/tokenimage/bonk.jpeg",
                tokenList: null,
                tokenIndex: 1,
                currentAction: null,  // 'like', 'dislike', 'superHeart', or null
                showHearts: false,
                showSuperHeart: false,
                showX: false,
                hearts: Array.from({ length: 1 }, (_, i) => i + 1),
                superHeartDuration: 600,
                heartDuration: 600,
                xDuration: 600,
                heartImgLink: "https://d2y4xlojzk24f7.cloudfront.net/media/Red%20Heart.png",
                superHeartImgLink: "https://d2y4xlojzk24f7.cloudfront.net/media/Two%20Hearts.png",
                xImgLink: "https://d2y4xlojzk24f7.cloudfront.net/media/Multiply.png",
                buys: 0
            };
        },
        mounted() {
            this.initializeAppSync()
            this.fetchLastToken()
            this.buildTokenList()
            this.subscribeToTokens()
            setInterval(() => {
                this.age = this.timeSince(this.tokenCreateTime)
            }, 1000)
        },
        beforeUnmount() {
            if (this.subscription) {
                this.subscription.unsubscribe()
            }
        },
        methods: {
            initializeAppSync() {
                this.client = generateClient()
            },
            async fetchLastToken() {
                try {
                    const munchedTokenData = await this.client.graphql({ query: queries.getTokenPool, variables: { key: 'latest'} })
                    const latestAddress = munchedTokenData.data.getTokenPool.address
                    const latestTokenData = await this.client.graphql({ query: queries.getMunchedTokens, variables: { new_token_address: latestAddress} })
                    this.name = latestTokenData.data.getMunchedTokens.name
                    this.symbol = latestTokenData.data.getMunchedTokens.symbol
                    this.address = latestTokenData.data.getMunchedTokens.new_token_address
                    this.tokenCreateTime = new Date(Date.parse(latestTokenData.data.getMunchedTokens.helius_webhook_time + '.000Z'))
                    this.imgLink = latestTokenData.data.getMunchedTokens.img_link
                } catch (error) {
                    console.error('Error performing query', error)
                }
            },
            async buildTokenList() {
                await this.fetchTokenLists()
                this.shuffleArray(this.tokenList)
            },
            async fetchTokenLists() {
                try {
                    const latestTokensRaw = await this.client.graphql({ query: queries.getTokenPool, variables: { key: 'latest_tokens'} })
                    const latestTokens = latestTokensRaw.data.getTokenPool.addresses
                    const historicalTokensRaw = await this.client.graphql({ query: queries.getTokenPool, variables: { key: 'historical_queue'} })
                    const historicalTokens = historicalTokensRaw.data.getTokenPool.addresses
                    // Combine the two lists
                    this.tokenList = latestTokens.concat(historicalTokens)
                } catch (error) {
                    console.error('Error performing query for token list', error)
                }
            },
            shuffleArray(array) {
                for (var i = array.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1))
                    var temp = array[i]
                    array[i] = array[j]
                    array[j] = temp
                }
            },
            async nextToken() {
                try {
                    const newTokenData = await this.client.graphql({ query: queries.getMunchedTokens, variables: { new_token_address: this.tokenList[this.tokenIndex]} })
                    this.name = newTokenData.data.getMunchedTokens.name
                    this.symbol = newTokenData.data.getMunchedTokens.symbol
                    this.address = newTokenData.data.getMunchedTokens.new_token_address
                    this.tokenCreateTime = new Date(Date.parse(newTokenData.data.getMunchedTokens.helius_webhook_time + '.000Z'))
                    this.imgLink = newTokenData.data.getMunchedTokens.img_link
                    this.tokenIndex++
                } catch (error) {
                    console.error('Error performing query', error)
                }
            },
            subscribeToTokens() {
                this.subscription = this.client.graphql({ query: subscriptions.onCreateMunchedTokens }).subscribe({
                    next: ({ data }) => {
                        this.name = data.onCreateMunchedTokens.name
                        this.symbol = data.onCreateMunchedTokens.symbol
                        this.address = data.onCreateMunchedTokens.new_token_address
                        this.tokenCreateTime = Date.parse(data.onCreateMunchedTokens.helius_webhook_time + ' UTC')
                        this.imgLink = data.onCreateMunchedTokens.img_link
                    },
                    error: (error) => console.error(error)
                })
            },
            timeSince(timeStamp) {
                var now = new Date()
                const secondsPast = (now.getTime() - timeStamp) / 1000;
                if (secondsPast < 60) {
                    return parseInt(secondsPast) + ' seconds';
                }
                if (secondsPast < 3600) {
                    return parseInt(secondsPast / 60) + ' minutes ' + Math.floor(secondsPast % 60) + ' seconds';
                }
                if (secondsPast <= 86400) {
                    return parseInt(secondsPast / 3600) + ' hours ' + Math.floor(secondsPast % 3600 / 60) + ' minutes';
                }
                if (secondsPast > 86400) {
                    return parseInt(secondsPast / 86400) + ' days ' + Math.floor(secondsPast % 86400 / 3600) + ' hours'
                }
            },
            handleClick(actionType) {
                this.showHearts = false;
                this.showSuperHeart = false;
                this.showX = false;
                if (actionType === 'like') {
                        this.showHearts = true;  // Show hearts for 'like'
                } else if (actionType === 'superHeart') {
                        this.showSuperHeart = true;  // Show super hearts for 'superHeart'
                } else if (actionType === 'x') {
                        this.showX = true;
                }
                setTimeout(() => {
                    this.showHearts = false;
                }, this.heartDuration); // match the duration of the animation
                setTimeout(() => {
                    this.showSuperHeart = false;
                }, this.superHeartDuration); // match the duration of the animation
                setTimeout(() => {
                    this.showX = false;
                }, this.xDuration);
                this.playSound(actionType)
                // Move to next token with a delay
                setTimeout(() => this.nextToken(), 300)
            },
            playSound(soundType) {
                if (soundType === 'like') {
                    this.emitSingleHeart();
                } else if (soundType === 'superHeart') {
                    this.emitDoubleHeart();
                } else if (soundType === 'x') {
                    
                }

                if (!this.soundEnabled) return;
                // Stop all sounds first
                this.$refs.heartAudio.pause();
                this.$refs.heartAudio.currentTime = 0;
                this.$refs.superheartAudio.pause();
                this.$refs.superheartAudio.currentTime = 0;
                this.$refs.xAudio.pause();
                this.$refs.xAudio.currentTime = 0;

                // Play the requested sound based on the action type
                if (soundType === 'like') {
                    this.$refs.heartAudio.play();
                } else if (soundType === 'superHeart') {
                    this.$refs.superheartAudio.play();
                } else if (soundType === 'x') {
                    this.$refs.xAudio.play();
                }
            },
            emitSingleHeart() {
                this.$emit('amountToBuy', {value: 1, timestamp: new Date().getTime() })
                this.$emit('tokenToBuy', this.address)
            },
            emitDoubleHeart() {
                this.$emit('amountToBuy', {value: 2, timestamp: new Date().getTime() })
                this.$emit('tokenToBuy', this.address)
            }
        }
    }
</script>


<style scoped>
    .rectangle {
        position: relative;
        height: 74vh;
        max-height: 725px;
        min-height: 550px;
        min-width: 370px;
        border: 2px solid #1e3e60;
        padding: .5vh; 
        box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2); /* Box shadow */
        display: flex; /* Use flexbox for button positioning */
        flex-direction: column; /* Arrange buttons vertically */
        z-index: 0;
        transition: height 0.5s ease;
        border-radius: 11px;
        overflow-y: hidden;
        overflow-x: hidden;
        margin: 0 auto;
        left: 0;
        right: 0;
        background-color: #ff000004;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .rectangle::-webkit-scrollbar {
        display: none;
    }
    .container-fluid {
        /* display: flex; */
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* Token sizing */
    .token-logo {
        display: flex;
        justify-content: center; /* Center image horizontally */
        align-items: center; /* Center image vertically */
        overflow: hidden; /* Prevents overflow if image is too large */
    }

    .token-logo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding-bottom: 10px;
        min-height: 430px;

    }


    .info-container {
        width: 100%;
        position: relative;
        padding-left: 1vw;

        text-align: left; 
        font-weight: bold;
    }
    .tokenInfo{
        display: block; /* Make each item appear on a new line */
        font-size: 22.5px;
        color: #333; /* Adjust color as needed */
        margin-bottom: 1vh; /* Adjust spacing between name and age */
    }

    /* Action buttons styles */
    .action-buttons {
        display: inline-flex; /* Use inline-flex to place buttons inline */
        align-self: flex-end;
        justify-content: center; /* Evenly space buttons */
        width: 100%; /* Full width */
        margin: 0 auto;
        margin-top: auto; /* Push buttons to the bottom */
        align-items: center;
        padding-top: 40px;
    }
    .button {
        /* width: 11vh; 
        height: 11vh;  */
        min-width: 120px;
        min-height: 120px; 
        border-radius: 50%;
        background-color: #9abde2;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; /* Center the text horizontally */
        box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2); /* Box shadow */
    }

    .button:active {
        transform: scale(0.95);
    }
    .heart-button::before {
        content: '❤️'; /* Heart emoji as content */
        font-size: clamp(50px, 4vw, 59px); /* Initial font size of the heart emoji */
        transition: font-size 0.3s ease; 
        display: flex; /* Use flex to center the content */
        justify-content: center;
        align-items: center;
        width: 100%; /* Full width to center the content */
    }
    .heart-button:hover::before {
        font-size: clamp(52px, 4.5vw, 65px); /* Increase the font size when hovered */
    }
    .heart-button {
        background-color: #19ed95; /* Green background color */
        border: 0.07vw solid slategrey;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; /* Center the text horizontally */
        /* box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);  */
        position: relative; /* Necessary for positioning the ::before content */
        overflow: visible; /* Ensures that the scaling emoji does not overflow the button boundaries */
        transition: box-shadow 0.3s ease; /* Transition for shadow */
    }
    .heart-button:hover {
        box-shadow: 0 0 2vw rgba(226, 30, 135, 0.8); 
    }
    .heart-cascade {
        position: absolute;
        top: 15%; /* Raise the position from the bottom */
        left: 0;
        width: 100%;
        text-align: center; /* Ensure hearts are centered horizontally */
        overflow: visible; /* Make sure hearts are visible when animating */
    }

    .heart-cascade span {
        animation: cascade 1200ms linear infinite;
        opacity: 0;
        font-size: 12em; /* Increase the size of the hearts */
        color: #ff467e;
        overflow: visible;
    }
    @keyframes cascade {
        0% {
            transform: translateY(-100%);
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    .x-button::before {
        content: '✖️'; /* X symbol */
        font-size: clamp(45px, 2.3vw, 53px); 
        transition: font-size 0.3s ease; /* Smooth transition for font size */
        display: flex; /* Use flex to center the content */
        justify-content: center;
        align-items: center;
        width: 100%; /* Full width to center the content */
    }
    .x-button:hover::before {
        font-size: clamp(46px, 2.35vw, 48px);
        transition: font-size 0.3s ease;
    }
    .x-button {
        background-color: #e4e0e0; /* Gray color for X button */
        border: 0.03vw solid slategrey;
        
    }
    .x-button:hover {
        box-shadow: 0 0 .1vw rgb(0, 0, 0);
    }
    .x-cascade {
        position: absolute;
        top: 15%; /* Raise the position from the bottom */
        left: 0;
        width: 100%;
        text-align: center; /* Ensure hearts are centered horizontally */
        overflow: visible; /* Make sure hearts are visible when animating */
    }

    .x-cascade span {
        animation: cascade 2s linear infinite;
        opacity: 0;
        font-size: 12em; /* Increase the size of the hearts */
        color: #ff467e;
        overflow: visible;
    }
    @keyframes cascade {
        0% {
            transform: translateY(-100%);
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    .super-heart-button::before {
        content: '💕'; 
        font-size: clamp(66px, 5vw, 74px); 
        position: absolute;
        
    }
    .super-heart-button:hover::before {
        font-size: clamp(68px, 5.5vw, 80px); 
        transition: font-size 0.3s ease; 
    }
    .super-heart-button{
        position: relative;
        bottom: 20%;
        background-color: #f9db79; 
        border: 0.05vw solid slategrey;
        /* box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);  */
        transition: box-shadow 0.3s ease; 
    }
    .super-heart-button:hover {
        box-shadow: 0 0 2vw rgba(238, 116, 211, 0.8); 
    }
    .super-heart-cascade {
        position: absolute;
        top: 15%; /* Raise the position from the bottom */
        left: 0;
        width: 100%;
        text-align: center; /* Ensure hearts are centered horizontally */
        overflow: visible; /* Make sure hearts are visible when animating */
    }
    .super-heart-cascade span {
        animation: cascade 1200ms linear infinite;
        opacity: 0;
        font-size: 15em; /* Increase the size of the hearts */
        color: #ff467e;
        overflow: visible;
    }
        /* Overlay when hearts are shown */

    .overlay-heart {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(229, 83, 83, 0.93); 
        z-index: 1;  
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .overlay-superheart {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(231, 156, 206, 0.93); 
        z-index: 1;  
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .overlay-x {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(223, 222, 223, 0.93); 
        z-index: 1;  
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .heart-cascade, .super-heart-cascade, .x-cascade {
        position: absolute;
        top: 15%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 2;  
    }





    @keyframes cascade {
        0% {
            transform: scale(0.5) translateY(-100%);
            opacity: 1;
    }
        100% {
            transform: scale(1.5) translateY(100%);
            opacity: 0;
    }
    }
    @media (width: 1024px) {
        .rectangle {
            width: 420px;
            margin: 0 auto;
        }
}

</style>