/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMunchedTokens = /* GraphQL */ `
  subscription OnCreateMunchedTokens {
    onCreateMunchedTokens {
      new_token_address
      helius_webhook_time
      name
      symbol
      img_link
      new_token_address
    }
  }
`;
export const onUpdateMunchedTokens = /* GraphQL */ `
  subscription OnUpdateMunchedTokens($new_token_address: String) {
    onUpdateMunchedTokens(new_token_address: $new_token_address) {
      new_token_address
      decimals
      delegated
      freezable
      helius_webhook_time
      liquidity_address
      lp_tokens_created
      mintable
      mutable
      name
      sol_supply_to_pool
      supply
      symbol
      token_owner
      token_supply_to_pool
      img_link_onchain
      img_link
      __typename
    }
  }
`;
export const onCreateTokenPool = /* GraphQL */ `
  subscription OnCreateTokenPool($key: String) {
    onCreateTokenPool(key: $key) {
      key
      address
      addresses
      __typename
    }
  }
`;
export const onUpdateTokenPool = /* GraphQL */ `
  subscription OnUpdateTokenPool($key: String) {
    onUpdateTokenPool(key: $key) {
      key
      address
      addresses
      __typename
    }
  }
`;
