import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVueNext from 'bootstrap-vue-next'
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue-next/dist/bootstrap-vue-next.css"
import { Amplify } from 'aws-amplify'
import SolanaWallets from 'solana-wallets-vue'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import 'solana-wallets-vue/styles.css'


Amplify.configure({
    API: {
        GraphQL: {
            endpoint: 'https://yv73lv462vb3xeloem66yqtqsy.appsync-api.us-east-2.amazonaws.com/graphql',
            region: 'us-east-2',
            defaultAuthMode: 'iam'
        }
    },
    Auth: {
        Cognito: {
            region: 'us-east-2',
            userPoolId: 'us-east-2_rUSCBGSuv',
            identityPoolId: 'us-east-2:4eb19449-94fb-4831-bace-3e215a927741',
            userPoolClientId: '7hirofuuo5p4qjs44qcvirgs67',
            allowGuestAccess: true
        }
    }
})

const walletOptions = {
    autoConnect: true,
}

const app = createApp(App)
app.use(BootstrapVueNext)
app.use(SolanaWallets, walletOptions)
app.mount('#app')
