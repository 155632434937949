<template>
    <header>
    <div class="navbar">
        <div class="left-container">
            <button @click="toggleMenu" class="hamburger-button">
            &#9776; <!-- Hamburger Icon -->
            </button>
            <div class="logo homepage">OnlyMemes</div>
        </div>
        <nav class="main-nav">
            <ul>
                <!-- <li><a href="about.html" class="about-link">About</a></li> -->
                <!-- <li><a href="disclaimer.html" class="disclaimer-link">Disclaimer</a></li>  -->
                <!-- <li><a href="#" class="connect-wallet-link">Connect Wallet</a></li>   -->
            </ul>
        </nav>
        <div class="wallet-adapater-holder">
          <wallet-multi-button></wallet-multi-button>
        </div>
        <transition
          name="slide"
          mode="out-in"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave">
          <div v-show="menuVisible" class="menu-items">
            <div class="how-to-play">
              <h2>How To Play:</h2>
              <div class="heart-value-input">
                  <span>Enter ❤️ Value = </span>
                  <div class="input-field-color">
                  <input type="text" id="heartValue" placeholder="e.g. 0.1 SOL" v-model="amountToBuy" @focus="clearPlaceholder" @blur="restorePlaceholder" @input="validateInput">
                  </div>
                </div>
              <div class="instructions">
                <p><span>❤️</span> = Spend 1 Heart</p>
                <p><span>💕</span> = Spend 2 Hearts</p>
                <p><span>✖️</span> = Pass</p>
              </div>
            </div> 
            <div class="link-position">
              <a href="#about" class="menu-item">About</a>
              <a href="#disclaimer" class="menu-item">Disclaimer</a> 
            </div>   
            <div class="toggle">
              <div class="label-switch-container">
                <div class="label">OnlyMemes</div>
                <label class="switch">
                    <input type="checkbox" v-model="onlyMemesMode" id="onlyMemesModeToggle" @change="emitOnlyMemesMode">
                    <span class="slider round"></span>
                </label>
              </div>
              <div class="label-switch-container-new">  
                <div class="label">Newest Tokens</div>
                <label class="switch">
                    <input type="checkbox" v-model="recentlyCreatedMode" id="recentlyCreatedToggle" @change="emitRecentlyCreatedMode">
                    <span class="slider round"></span>
                </label>
              </div>
              <div class="label-switch-container">
                <div class="label">Mute</div>
                <label class="switch">
                  <input type="checkbox" v-model="soundEnabled" id="soundToggle" @change="emitSoundToggle">
                  <span class="slider round"></span>
                </label>
              </div>
                <!-- <span v-if="onlyMemesMode" class="mode-enabled">OnlyMemes Mode Enabled</span> -->
            </div>
          </div>
        </transition>
    </div>
  </header>
</template>


<script>
  import { WalletMultiButton } from 'solana-wallets-vue'

  export default {
    name: 'HeaderMeme',
    components: {
      WalletMultiButton
    },
    data() {
      return {
        menuVisible: false, // State to track visibility of the hamburger menu
        onlyMemesMode: false,
        recentlyCreatedMode: false,
        soundEnabled: true,
        amountToBuy: 100
      };
    },
    mounted() {
      // Set menuVisible based on window width
      this.menuVisible = window.innerWidth >= 1024;
      this.applyMenuPosition();
    },
    methods: {
      // Toggles the visibility of the menu
      toggleMenu() {
        this.menuVisible = !this.menuVisible;
        this.applyMenuPosition();
      },
      openMenu() {
        this.menuVisible = true;
        this.applyMenuPosition();
      },
      applyMenuPosition() {
        const menu = this.$el.querySelector('.menu-items');
        if (this.menuVisible) {
          menu.style.left = "0"; // End position on-screen
        } else {
          menu.style.left = "-250px"; // Start position off-screen
        }
      },
      // Prepares the element for the 'enter' transition
      beforeEnter(el) {
        el.style.left = "-250px"; // Start position off-screen
      },
      // Handles the 'enter' transition
      enter(el, done) {
        el.getBoundingClientRect(); // Forces reflow
        el.style.transition = 'left 1s ease'; // Smooth transition for the left property
        el.style.left = "0"; // End position on-screen
        done();
      },
      // Handles the 'leave' transition
      leave(el, done) {
        el.style.left = "-250px"; // Moves the element back off-screen
        done();
      },
      clearPlaceholder(event) {
        event.target.placeholder = '';
      },
      restorePlaceholder(event) {
        if (!event.target.value) {
          event.target.placeholder = 'e.g. 0.1 SOL';
        }
      },
      validateInput(event) {
        const value = event.target.value;
        event.target.value = value.replace(/[^0-9.]/g, '');
        this.$emit('amountToBuy', parseFloat(this.amountToBuy))
      },
      emitOnlyMemesMode() {
        this.$emit('onlyMemesModeChange', this.onlyMemesMode); // Migrated method
      },
      emitRecentlyCreatedMode() {
        this.$emit('recentlyCreatedModeChange', this.recentlyCreatedMode);
      },
      emitSoundToggle() {
        this.$emit('soundToggleChange', this.soundEnabled);
      }
    }
  }
</script>



<style>
/* Header styles */
header {
    background-color: #FF6B6B;
    padding: 0px 0px 0px 0px;
    height: 100px;
    margin-bottom: 1vh;
    position: static;
    width: 100%;
}

/* Overrides bootstrap cutting off bottom of header */
header, header::before, header::after {
  box-sizing: content-box;
}


.navbar {
  z-index: 5;
}

.left-container {
    display: flex;
    align-items: center;
}
.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  padding: 20px;
  cursor: pointer;
  z-index: 99; /* Ensures the button is above the sliding menu */
}

.menu-items {
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 350px; /* Adjust width of the sidebar menu */
  position: fixed;
  top: 0;
  left: -250px; /* Start off-screen */
  transition: left 0.3s ease;
  height: 100%;
}
.heart-value-input {
  font-size: x-large;
  font-weight: 500;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
  background-color: #FF6B6B;
  width: 100%;
}
.heart-value-input input::placeholder {
  color: #bbb;
  font-family: "Open Sans";
}
.heart-value-input input:focus {
  outline: none;
  border-color: #20c997;
}
/* Chrome, Edge, Safari Remove Spin Buttons */
.heart-value-input input::-webkit-inner-spin-button,
.heart-value-input input::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Removes the default appearance of the spin button in WebKit browsers */
    margin: 0; /* Removes any margin that might be associated */
}
/* General styling to ensure no other browser shows a spinner */
.heart-value-input input[type=number]::-webkit-clear-button {
    display: none; /* Removes clear buttons in input fields in IE and WebKit browsers */
}
.menu-item {
  color: white;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.menu-item:hover {
  background-color: #555;
}

.slide-enter-active, .slide-leave-active {
  transition: left 0.3s ease;
}

.slide-enter-from, .slide-leave-to {
  left: -250px; /* Start and end off-screen */
}

.slide-enter-to, .slide-leave-from {
  left: 0; /* End and start on-screen */
}


/* Logo styles */
.logo {
    color: #343a40; 
    border: 4px solid #343a40;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: clamp(12px, 2.7vh, 24px);
}

.logo-button {
    color: #343a40; 
    border: 4px solid #343a40;
    padding: 5.5px 11px;
    border-radius: 34px;
    font-size: 36px;
    margin: 0 auto; /* Center the logo-button */
    text-decoration: none; /* Remove underline */
    display: relative; 
    width: fit-content; 
}


/* Navigation styles */
nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

nav ul li {
    margin-right: 20px;
}

/* Link styles */
nav ul li a {
    color: #343a40; /* Dark text color for links */
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth color transition on hover */
}

nav ul li a:hover {
    color: #495057; /* Slightly darker text color on hover */
}
.main-nav {
  margin-left: auto;
  align-items: center;
}
.wallet-adapater-holder {
  margin-right: 2vw;
}
/* Styles for "Connect Wallet" link */
.connect-wallet-link {
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #20c997; /* Refreshing green color for button */
  color: white; /* White text color */
  /* text-decoration: none; */
  transition: background-color 0.3s ease; /* Smooth color transition on hover */
  display: block;
  margin-left: auto;
}

.connect-wallet-link:hover {
    background-color: #84efff; 
}

/* How to Play section styles */
.how-to-play {
    color: white;  /* Ensures text is visible against a dark background */
    padding: 10px; /* Add some padding around the text */
    font-size: x-large;
    margin-top: 45%;
    padding-bottom: 58vh;
    z-index: 5;
    position: relative;
}
.instructions {
  top: min(10px);
  position: relative;
}


.toggle {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  left: 65%;
  padding-right: 5%;
  padding-left: 0;
  transform: translateX(-50%);
  font-size: clamp(20px, 1.8vh, 22px);
}
.label-switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}
.label-switch-container-new {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}
.label {
    position: relative;
    color: #cad3e6; /* Matching the color scheme of other text */
    /* font-size: 1.8vh; */
    font-weight: 800;
    margin-left: auto;
    padding-right: 5px;
    display: flex;
    font-size: clamp(22px, 1.8vh, 22px);
    white-space: nowrap;
}
.link-position {
  position: absolute;
  display: block;
  bottom: 5vh;
  text-align: center;
  padding-left: 35%;
  
}
/* Toggle switch styling */
.switch {
  position: relative;
  width: clamp(50px, 4vh, 56px);
  height: clamp(32px, 2.5vh, 56px);
}

/* Hide default HTML checkbox */
.switch input { 
  opacity: 0;
  position: absolute;
  width: 0;
  min-width: 0;
  max-width: 0;
  height: 0;
  z-index: 2;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8d8d8d;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 72%;
  width: 50%;
  left: 5px;
  bottom: 15%;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(55%);
}

@media (max-width: 768px) {
    .banner {
        flex-direction: column;
        height: 10vh; /* Adjust height for stacked layout */
    }
}
@media (max-width: 768px) {
    body, html {
        width: 100%;
        overflow-x: hidden; /* Prevent horizontal scrolling */
        overflow-y: hidden;
    }
}
@media (min-height: 1024px) {
    header {
        margin-bottom: 40px;
    }
}
@media (width: 912px) {
  .main-nav, .header {
    padding-right: 5vw;
    overflow-x: hidden;
  }
}
@media (width: 768) {
  .connect-wallet-link {
    padding-left: 20vw;
  }
}

</style>
