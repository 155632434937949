/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMunchedTokens = /* GraphQL */ `
  query GetMunchedTokens($new_token_address: String!) {
    getMunchedTokens(new_token_address: $new_token_address) {
      helius_webhook_time
      name
      symbol
      img_link
      new_token_address
    }
  }
`;
export const getTokenPool = /* GraphQL */ `
  query GetTokenPool($key: String!) {
    getTokenPool(key: $key) {
      key
      address
      addresses
      __typename
    }
  }
`;
export const listTokenPools = /* GraphQL */ `
  query ListTokenPools(
    $filter: TableTokenPoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenPools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        key
        address
        addresses
        __typename
      }
      nextToken
      __typename
    }
  }
`;
