/<template>
  
</template>

<script>
import { useWallet } from 'solana-wallets-vue'
import { Transaction, SystemProgram, Connection, clusterApiUrl } from '@solana/web3.js';
import fetch from 'cross-fetch';
import { Wallet } from '@project-serum/anchor';
import bs58 from 'bs58';

export default {
    name: 'Rectangle',
    data() {
        return {
            amtToBuy: 0,
            rpcEndpoint: 'https://sarine-wdy1tk-fast-mainnet.helius-rpc.com'
        };
    },
    mounted() {
        
    },
    methods: {
        buy(amt) {
            console.log('Want to buy: ', amt)
            this.transact(amt)
            console.log('Bought: ', amt)
        },
        async transact(amt) {
            // const connection = new Connection(clusterApiUrl('testnet'), 'confirmed')
            const connection = new Connection(this.rpcEndpoint, 'confirmed')
            const { publicKey, sendTransaction } = useWallet()

            let transaction = new Transaction()

            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: publicKey.value,
                    toPubkey: 'GGuCH7NLhqm3QMtJmtqNrxo2ZbLjmN6gVZPqYcvxJmGd',
                    lamports: 10000000 * amt
                })
            )

            try {
                const signature = await sendTransaction(transaction, connection)
                const latestBlockHash = await connection.getLatestBlockhash()
                await connection.confirmTransaction({
                    blockhash: latestBlockHash.blockhash,
                    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                    signature: signature
                })
            } catch (error) {
                console.error(error)
            }
        }
    },
    props: {
        singleHeartBuy: Number,
        amountToBuy: {
            type: Object,
            required: true
        },
        tokenToBuy: String
    },
    watch: {
        amountToBuy(amt) {
            this.buy(amt.value)
        }
    }
}

</script>


<style>

</style>